/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'error': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<mask id="svgicon_error_a" maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 1.333A6.67 6.67 0 001.333 8 6.67 6.67 0 008 14.667 6.67 6.67 0 0014.667 8 6.67 6.67 0 008 1.333zm0 7.334A.669.669 0 017.333 8V5.333c0-.366.3-.666.667-.666.367 0 .667.3.667.667V8c0 .367-.3.667-.667.667zM7.333 10v1.334h1.334V10H7.333z" _fill="#000"/></mask><g mask="url(#svgicon_error_a)"><path pid="1" _fill="#DA1414" d="M0 0h16v16H0z"/></g><g mask="url(#svgicon_error_a)"><path pid="2" _fill="#DA1414" d="M0 0h16v16H0z"/></g>'
  }
})
