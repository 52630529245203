/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'enterprise': {
    width: 60,
    height: 60,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M30 60c16.569 0 30-13.431 30-30C60 13.431 46.569 0 30 0 13.431 0 0 13.431 0 30c0 16.569 13.431 30 30 30z" _fill="#C6D6EC"/><mask id="svgicon_enterprise_a" maskUnits="userSpaceOnUse" x="0" y="0" width="60" height="60"><path pid="1" d="M30 60c16.569 0 30-13.431 30-30C60 13.431 46.569 0 30 0 13.431 0 0 13.431 0 30c0 16.569 13.431 30 30 30z" _fill="#C6D6EC"/></mask><g mask="url(#svgicon_enterprise_a)"><path pid="2" d="M18.103 17.756h23.229V59.06H18.103V17.756z" _fill="#4472B2"/><path pid="3" d="M41.332 21.51h9.955v3.756h-9.955V21.51z" _fill="#96CC7F"/><path pid="4" d="M41.332 25.266h11.614V59.06H41.332V25.266z" _fill="#669E4F"/><path pid="5" d="M18.103 25.266H8.148V21.51h9.955v3.755z" _fill="#96CC7F"/><path pid="6" d="M18.103 59.061H6.489V25.266h11.614V59.06z" _fill="#669E4F"/><path pid="7" d="M9.807 36.531h3.319v3.755H9.806v-3.755zM9.807 44.041h3.319v3.755H9.806v-3.755zM9.807 51.551h3.319v3.755H9.806v-3.755zM49.628 40.286h-3.319V36.53h3.319v3.755zM9.807 29.021h3.319v3.755H9.806v-3.755zM49.628 32.776h-3.319V29.02h3.319v3.755zM49.628 47.796h-3.319v-3.755h3.319v3.755zM38.013 47.796h-3.318v-3.755h3.318v3.755zM31.377 47.796h-3.319v-3.755h3.319v3.755zM24.74 47.796h-3.318v-3.755h3.318v3.755zM33.036 59.061v-7.51h-6.637v7.51M38.013 32.776h-3.318V29.02h3.318v3.755zM31.377 32.776h-3.319V29.02h3.319v3.755zM24.74 32.776h-3.318V29.02h3.318v3.755zM38.013 40.286h-3.318V36.53h3.318v3.755zM31.377 40.286h-3.319V36.53h3.319v3.755zM24.74 40.286h-3.318V36.53h3.318v3.755zM49.628 55.306h-3.319v-3.755h3.319v3.755z" _fill="#fff"/><path pid="8" d="M4 58.122h51.435V60H4v-1.878z" _fill="#4D5D7A"/></g>'
  }
})
