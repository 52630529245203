/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'infoCircle': {
    width: 44,
    height: 44,
    viewBox: '0 0 44 44',
    data: '<rect pid="0" width="44" height="44" rx="18" _fill="#286EDD"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M22 12c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 15c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm-1-8h2v-2h-2v2z" _fill="#fff"/>'
  }
})
