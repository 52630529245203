/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'addrIcon': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<rect pid="0" width="32" height="32" rx="16" _fill="#559E6D"/><path pid="1" d="M14.665 20.885v-3.333h2.667v3.333c0 .367.3.667.666.667h2c.367 0 .667-.3.667-.667v-4.667H21.8c.306 0 .453-.38.22-.58l-5.574-5.02a.672.672 0 00-.893 0l-5.574 5.02c-.226.2-.086.58.22.58h1.134v4.667c0 .367.3.667.666.667h2c.367 0 .667-.3.667-.667z" _fill="#fff"/>'
  }
})
