/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eyeOff': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M16.875 17.5a.623.623 0 01-.442-.183L2.683 3.567a.625.625 0 01.884-.884l13.75 13.75a.625.625 0 01-.442 1.067zM9.688 12.338l-2.023-2.023a.078.078 0 00-.133.066 2.504 2.504 0 002.09 2.09.078.078 0 00.087-.09.078.078 0 00-.021-.043zM10.313 7.662l2.026 2.026a.078.078 0 00.133-.066A2.506 2.506 0 0010.38 7.53a.078.078 0 00-.068.132z" _fill="#A5BCE0"/><path pid="1" d="M19.18 10.678a1.258 1.258 0 00-.004-1.358c-1.034-1.598-2.375-2.956-3.878-3.927-1.665-1.075-3.501-1.643-5.31-1.643a8.849 8.849 0 00-2.806.46.156.156 0 00-.06.26l1.844 1.845a.156.156 0 00.15.04 3.75 3.75 0 014.53 4.532.155.155 0 00.042.149l2.654 2.656a.156.156 0 00.211.01 13.43 13.43 0 002.627-3.024zM10 13.75a3.75 3.75 0 01-3.644-4.634.156.156 0 00-.041-.149L3.704 6.355a.156.156 0 00-.212-.01C2.54 7.16 1.656 8.149.85 9.305a1.247 1.247 0 00-.025 1.388c1.032 1.614 2.359 2.974 3.839 3.932 1.668 1.079 3.459 1.626 5.325 1.626a9.309 9.309 0 002.837-.451.157.157 0 00.103-.191.155.155 0 00-.04-.069l-1.854-1.854a.157.157 0 00-.15-.04c-.289.07-.586.105-.884.105z" _fill="#A5BCE0"/>'
  }
})
