<template>
    <div>
        <label class="container">
            <input :value="value"  type="radio" :name="name" :checked="isChecked" @change="onChange">
            <span class="checkmark"></span>
            <span >{{label}}</span>
            <slot name="specificText"></slot>
        </label>
    </div>
</template>

<script>
export default {
    props: {
        label: String,
        name: String,
        radioButtonValue:null,
        initialVal: null,
        checked: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            check: this.checked,
            value: ''
        }
    },

    methods: {
        testCheck(){
            
        },
        onChange(event){
            this.$emit('change', event.target.checked)
            this.$emit('input', this.radioButtonValue)
            this.value = this.radioButtonValue
            this.check = event.target.checked
        }
    },

    computed: {
        isChecked(){
            if (JSON.stringify(this.radioButtonValue) == JSON.stringify(this.value) || this.check || JSON.stringify(this.radioButtonValue) == JSON.stringify(this.initialVal)) {
                return true 
            }
            else {
                return false
            }
        }
    },
}
</script>

<style scoped>
/* The container */
.container {
    display: block;
    position: relative;
    padding-left: 28px;
    /* margin-bottom: 12px; */
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: white/* #eee */;
    border-radius: 50%;
    border-width: 2px;
    border-color: black /* #5F738C */
  
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: white;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
    border-color: black;
    background-color: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after{
    top: 3px;
	left: 3px;
	width: 8px;
	height: 8px;
    border-width: 1px;
    border-color: black;
    border-radius: 50%;
    background: black;
}
</style>