/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phoneIcon': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<rect pid="0" width="32" height="32" rx="16" _fill="#559E6D"/><path pid="1" d="M20.814 18.179l-1.693-.194a1.327 1.327 0 00-1.093.38L16.8 19.592a10.03 10.03 0 01-4.393-4.393l1.233-1.234c.287-.286.427-.686.38-1.093l-.193-1.68a1.334 1.334 0 00-1.327-1.18h-1.153c-.754 0-1.38.627-1.334 1.38.354 5.693 4.907 10.24 10.594 10.593.753.047 1.38-.58 1.38-1.333V19.5c.006-.674-.5-1.24-1.174-1.32z" _fill="#fff"/>'
  }
})
