/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'collectCheck': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M24 4.5C13.248 4.5 4.5 13.248 4.5 24S13.248 43.5 24 43.5 43.5 34.752 43.5 24 34.752 4.5 24 4.5zm10.148 12.965l-12.6 15a1.5 1.5 0 01-1.125.535h-.025a1.5 1.5 0 01-1.115-.497l-5.4-6a1.5 1.5 0 112.23-2.006l4.246 4.717 11.493-13.679a1.5 1.5 0 012.296 1.93z" _fill="#27AE60"/>'
  }
})
