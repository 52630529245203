/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mail': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2l.01-12c0-1.1.89-2 1.99-2zm8.53 8.67l7.07-4.42a.85.85 0 10-.9-1.44L12 11 5.3 6.81a.85.85 0 10-.9 1.44l7.07 4.42c.32.2.74.2 1.06 0z" _fill="#09101D"/>'
  }
})
