<template>
  <div id="app" class="fontBni">
    <router-view></router-view>
  </div>
</template>

<script>
import './assets/svgcomponents'
export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>
.fontBni{
  font-family: 'HelveticaNeueCyr', sans-serif; 
}
</style>