/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'selectArrow': {
    width: 25,
    height: 24,
    viewBox: '0 0 25 24',
    data: '<mask id="svgicon_selectArrow_a" maskUnits="userSpaceOnUse" x="5" y="8" width="15" height="8"><path pid="0" d="M5.582 10.979l5.05 4.512c.76.679 1.989.679 2.749 0l5.05-4.512C19.659 9.88 18.78 8 17.046 8h-10.1c-1.734 0-2.592 1.881-1.364 2.979z" _fill="#000"/></mask><g mask="url(#svgicon_selectArrow_a)"><path pid="1" d="M.004 0h24v24h-24V0z" _fill="#858C94"/></g>'
  }
})
