/* eslint-disable */
require('./addrIcon')
require('./avatar')
require('./bni')
require('./calendar')
require('./checkStep')
require('./chevronPrev')
require('./closeMod')
require('./collectCheck')
require('./enterprise')
require('./error')
require('./eye')
require('./eyeOff')
require('./infoCircle')
require('./mail')
require('./mailIcon')
require('./online')
require('./particular')
require('./phoneIcon')
require('./pwd')
require('./registerSuccess')
require('./selectArrow')
require('./uploadId')
