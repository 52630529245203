/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevronPrev': {
    width: 36,
    height: 36,
    viewBox: '0 0 36 36',
    data: '<path pid="0" d="M22 10.94a1.328 1.328 0 00-1.88 0L14 17.06c-.52.52-.52 1.36 0 1.88l6.12 6.12c.52.52 1.36.52 1.88 0 .52-.52.52-1.36 0-1.88l-5.173-5.187L22 12.82a1.34 1.34 0 000-1.88z" _fill="#6D7580"/>'
  }
})
