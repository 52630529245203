import axios from 'axios'
import { store } from '@/store/store'
export default function authGuard(next) {
    const token = localStorage.getItem('token')
    const header = { "Authorization": `Bearer ${token}` }
    axios.get(`/users/me`, {
            headers: header
        })
        .then(async res => {
            console.log(res)
            const userData = res.data
            localStorage.setItem('token', userData.token)
            await store.commit('mutUserData', {
                token: userData.token,
                profile: userData.user.profile,
                id: userData.user.id,
                hasSurvey: userData.user.hasSurvey,
                username: userData.user.username,
                lastname: userData.user.lastName,
                firstname: userData.user.firstName
            })
            next()

        })
        .catch(err => {
            console.log(err)
            next('/')
        })
}