import Vue from 'vue'
import App from './App.vue'
import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL
const token = localStorage.getItem('token')
const header = { "Authorization": `Bearer ${token}` }
export const instanceApi = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    headers: header
})
import VCalendar from 'v-calendar';

Vue.use(VCalendar, {
    componentPrefix: 'vc',
});
import SvgIcon from 'vue-svgicon'

// Default tag name is 'svgicon'
Vue.use(SvgIcon, {
    tagName: 'svgicon',
    isOriginalDefault: true
})

import './assets/styles/tailwind.css';
import VueRouter from 'vue-router'
import { routes } from './routes/routes'

import Modal from '@/components/modal/Modal'
import MInput from '@/components/globalComponents/MInput'
import MSelect from '@/components/globalComponents/MSelect'
import MFileInput from '@/components/globalComponents/MFileInput'
import MRadio from '@/components/globalComponents/MRadio'
import MButton from '@/components/globalComponents/MButton'
import LoaderCss from '@/components/LoaderCss'

import { store } from './store/store'

Vue.component("Modal", Modal);
Vue.component("MInput", MInput);
Vue.component("MSelect", MSelect);
Vue.component("MFileInput", MFileInput);
Vue.component("MRadio", MRadio);
Vue.component("MButton", MButton);
Vue.component("LoaderCss", LoaderCss);

import { ValidationProvider, ValidationObserver } from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
import { extend } from 'vee-validate';
import { required, email, min, max, numeric, confirmed, mimes } from 'vee-validate/dist/rules';

extend('email', {
    ...email,
    message: 'L\'adresse e-mail est invalide'
});
extend('confirmed', {
    ...confirmed,
    message: '{_field_} n\'est pas conforme'
});
extend('numeric', {
    ...numeric,
    message: '{_field_} doit contenir que des chiffres'
});
extend('mimes', {
    ...mimes,
    message: '{_field_} doit contenir que des images'
});
extend('min', {
    ...min,
    message: '{_field_} est invalide'
});
extend('max', {
    ...max,
    message: '{_field_} est invalide'
});
extend('required', {
    ...required,
    message: '{_field_} est requis(e)'
});
extend('upCase', { validate: value => value.match(/[A-Z]+/g) !== null, message: '{_field_} doit contenir au moins une majuscule' });
extend('oneNumber', { validate: value => value.match(/[0-9]+/g) !== null, message: '{_field_} doit contenir au moins un chiffre' });
extend('oneCharac', { validate: value => value.match(/^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/g) !== null, message: '{_field_} doit contenir au moins un caractère spécial' });

Vue.config.productionTip = false
Vue.use(VueRouter)

const router = new VueRouter({
    routes,
    mode: 'hash',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        return { x: 0, y: 0 }
    },
});
console.log(router);
new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')