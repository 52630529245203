/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkStep': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M7.33 13.23l-2.892-2.893a.83.83 0 10-1.175 1.175l3.483 3.484a.83.83 0 001.175 0l8.817-8.817a.83.83 0 10-1.175-1.175L7.329 13.23z" _fill="#fff"/>'
  }
})
