import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios'


Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        registerUsername: null,
        userData: {
            token: null,
            profile: null,
            id: null,
            hasSurvey: null,
            username: null,
            lastname: null,
            firstname: null,
        },
    },
    getters: {
        getRegisterUsername(state) {
            return state.registerUsername
        },
        getUser(state) {
            return state.userData
        },
    },
    mutations: {
        mutRegisterUsername(state, username) {
            state.registerUsername = username;
        },
        mutUserData(state, userInfo) {
            state.userData = {
                id: userInfo.id,
                token: userInfo.token,
                profile: userInfo.profile,
                hasSurvey: userInfo.hasSurvey,
                username: userInfo.username,
                lastname: userInfo.lastname,
                firstname: userInfo.firstname,
            };
        },
        /* mutRegister(state, email) {
            axios.post('/users/send-otp', {
                    username: email
                })
                .then(res => {
                    state.registerUsername = email
                    this.$router.push('/verifyUser')
                    console.log(res)
                })
                .catch(err => {
                    this.request = false
                    console.log(err.response)
                })
        },
        mutVerify(state, code) {
            axios.post('/users/check-otp', {
                    code: code
                })
                .then(res => {
                    console.log(res)
                    this.$router.push('/validate/subscription')
                })
                .catch(err => {
                    console.log(err.response)
                    this.invalidCode = true
                })
        } */

    },

    actions: {
        async registerUser({ state }, email) {
            state.registerUsername = email
            axios.post('/users/send-otp', {
                    username: email
                })
                .then(res => {

                    Promise.resolve(res)
                })
                .catch(err => {
                    Promise.reject(err)
                })
        },
        verifyUser({ commit }, code) {
            commit('mutVerify', code)
        }
    },

    modules: {}
});