/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar': {
    width: 24,
    height: 25,
    viewBox: '0 0 24 25',
    data: '<path pid="0" d="M19.92 5.094h-1.768v2.524c0 .826-.633 1.484-1.427 1.484h-.45c-.794 0-1.427-.661-1.427-1.484V5.094H9.434v2.524c0 .826-.644 1.484-1.438 1.484h-.45c-.795 0-1.452-.661-1.452-1.484V5.094H4.079C3.485 5.094 3 5.614 3 6.229v14.407c0 .616.485 1.122 1.08 1.122h15.84c.595 0 1.08-.506 1.08-1.122V6.23c0-.615-.485-1.135-1.08-1.135zm-.432 14.326a.792.792 0 01-.79.79H5.302a.792.792 0 01-.791-.79v-8.086c0-.436.355-.791.79-.791h13.395c.436 0 .791.355.791.791v8.086z" _fill="#171B1E"/><path pid="1" d="M7.546 8.469h.45c.446 0 .805-.387.805-.851V4.602c0-.465-.359-.844-.805-.844h-.45a.835.835 0 00-.82.844v3.016c0 .464.373.85.82.85zM16.275 8.469h.45c.447 0 .794-.387.794-.851v-3.02c0-.464-.347-.84-.794-.84h-.45c-.446 0-.794.376-.794.84v3.016c0 .468.348.855.794.855zM8.977 12.09H5.812v2.672h3.165V12.09zM8.977 15.992H5.812v2.672h3.165v-2.672zM13.582 12.09h-3.164v2.672h3.164V12.09zM13.582 15.992h-3.164v2.672h3.164v-2.672zM18.293 12.09h-3.164v2.672h3.164V12.09zM18.293 15.992h-3.164v2.672h3.164v-2.672z" _fill="#171B1E"/>'
  }
})
