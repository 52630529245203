/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'closeMod': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.293 8.293a1 1 0 011.414 0l14 14a1 1 0 11-1.414 1.414l-14-14a1 1 0 010-1.414z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M23.707 8.293a1 1 0 010 1.414l-14 14a1 1 0 01-1.414-1.414l14-14a1 1 0 011.414 0z" _fill="#000"/>'
  }
})
