/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mailIcon': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<rect pid="0" width="32" height="32" rx="16" _fill="#559E6D"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M10.667 10.667h10.666c.734 0 1.334.6 1.334 1.333v8c0 .733-.6 1.333-1.334 1.333H10.667c-.734 0-1.334-.6-1.334-1.333v-8c0-.733.6-1.333 1.334-1.333zm6.04 5.56l4.36-2.727a.566.566 0 10-.6-.96L16 15.333l-4.467-2.793a.566.566 0 10-.6.96l4.36 2.727c.434.273.98.273 1.414 0z" _fill="#fff"/>'
  }
})
