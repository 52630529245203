const Auth = () =>
    import ('@/pages/Auth')
const Dashboard = () =>
    import ('@/pages/Dashboard')
const Login = () =>
    import ('@/pages/components/Auth/Login')
const Register = () =>
    import ('@/pages/components/Auth/Register')

const Verification = () =>
    import ('@/pages/components/Auth/Verification')
const RegisterValidate = () =>
    import ('@/pages/components/Auth/RegisterValidate')
const PwdReset = () =>
    import ('@/pages/components/Auth/PwdReset')

const DHome = () =>
    import ('@/pages/components/Dashboard/DHome')
const DCollectSuccess = () =>
    import ('@/pages/components/Dashboard/DCollectSuccess')
const DProfil = () =>
    import ('@/pages/components/Dashboard/DProfil')

import { store } from '../store/store'
import authGuard from './authGuard'
import axios from 'axios'



export const routes = [{
        path: '/',
        name: 'Auth',
        component: Auth,
        children: [{
                path: '/',
                component: Login,
                beforeEnter(to, from, next) {
                    const token = localStorage.getItem('token')
                    const header = { "Authorization": `Bearer ${token}` }
                    axios.get(`/users/me`, {
                            headers: header
                        })
                        .then(res => {
                            console.log(res)
                            next('/dashboard')

                        })
                        .catch(err => {
                            console.log(err)
                            next()
                        })
                }

            },
            {
                path: '/register',
                component: Register,
            },
            {
                path: '/verifyUser',
                component: Verification,
                async beforeEnter(to, from, next) {
                    //console.log('loss', store.state.registerUsername);
                    if (store.state.registerUsername) {
                        next()
                    } else {
                        next('/register')
                    }
                },
            },
            {
                path: '/validate/subscription',
                component: RegisterValidate,
                async beforeEnter(to, from, next) {
                    if (store.state.registerUsername) {
                        next()
                    } else {
                        next('/register')
                    }
                },
            },
            {
                path: '/pwdReset',
                component: PwdReset,
            },
        ]
    },
    {
        path: '/dashboard',
        name: 'Dash',
        component: Dashboard,
        beforeEnter(to, from, next) {
            authGuard(next)
        },
        children: [{
                path: '',
                component: DHome,
                beforeEnter(to, from, next) {
                    if (store.state.userData.hasSurvey) next('/dashboard/success')
                    else {
                        next()
                    }
                }
            },
            {
                path: 'profil',
                component: DProfil,
            },
            {
                path: 'success',
                component: DCollectSuccess,
            },
        ]
    }, {
        path: '*',
        redirect: '/'
    },
    /* 
        { 
            path: '/dashboard', 
            redirect: '/dashboard/home' 
        } */

]