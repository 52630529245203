/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'uploadId': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.286 17.714H5.57a2.857 2.857 0 115.715 0zM22 8.564v11.157A2.279 2.279 0 0119.721 22H4.28A2.279 2.279 0 012 19.721V8.564a2.279 2.279 0 012.279-2.278h4.864V4.857a2.857 2.857 0 015.714 0v1.429h4.864A2.279 2.279 0 0122 8.564zM10.571 4.143a1.428 1.428 0 102.857 0 1.428 1.428 0 00-2.857 0zm2.143 13.571a4.287 4.287 0 00-2.221-3.75 2.858 2.858 0 10-4.129 0 4.285 4.285 0 00-2.221 3.75v.715a.714.714 0 00.714.714H12a.714.714 0 00.714-.714v-.715zm7.143.715a.714.714 0 00-.714-.715H15.57a.714.714 0 100 1.429h3.572a.714.714 0 00.714-.714zm0-2.858a.714.714 0 00-.714-.714H15.57a.714.714 0 000 1.429h3.572a.714.714 0 00.714-.715zm0-5.714a.714.714 0 00-.714-.714H15.57a.714.714 0 00-.714.714v2.857a.714.714 0 00.714.715h3.572a.714.714 0 00.714-.715V9.857zM16.286 12h2.143v-1.429h-2.143V12zm-7.857 1.429a1.428 1.428 0 100-2.857 1.428 1.428 0 000 2.857z" _fill="#6D7580"/>'
  }
})
